import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import { generateUrlForStates } from '../../../utils';
import BaseComponent from '../../base/base-component';
import { ICyclePercentChartPoint } from '../../components/charts/cycle-percent/cycle-percent-interface';
import { ICycleScoreChartPoint } from '../../components/charts/cycle-score/cycle-score-interface';
import ContainerBox from '../../components/container-box/container-box';
import CriteriaGrade from '../../components/criteria-grade/criteria-grade';
import PolarizationDetails from '../../components/details/polarization/polarization-details';
import ImproveBox from '../../components/improve-box/improve-box';
import Share from '../../components/share/share';
import SocialMetadata from '../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../components/social-metadata/social-metadata-enum';
import StateLayout from '../../components/state-layout/state-layout';
import { ISimilarStatesItem } from '../../components/states/similar-states/item/item-interface';
import SimilarStates from '../../components/states/similar-states/similar-states';
import {
  SODComponents,
  SODCycles,
  SODGrade,
  SODMeasures,
} from '../../global/global-enum';
import { ISODNode } from '../../global/global-interfaces';
import { IStatePolarizationTemplateProperties } from './state-polarization-interface';

export default class StatePolarizationTemplate extends BaseComponent<IStatePolarizationTemplateProperties> {
  protected declareTranslateCollection(): string | undefined {
    return 'statePolarizationTemplate';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const {
      polarizationHouse,
      polarizationSenate,
      polarizationTotal,
      lesserScoreSimilarStates,
      greaterScoreSimilarStates,
      icon,
    } = data;
    const { breadCrumbs, state, pageContent } = pageContext;
    const {
      polarizationScoreDesc,
      improvePolarizationDesc,
      improvePolarizationSolution1,
      improvePolarizationSolution2,
      improvePolarizationSolution3,
      houseDesc,
      senateDesc,
    } = pageContent;

    const polarizationAverage = this.populateAverageData(SODComponents.TOTAL);
    const houseAverage = this.populateAverageData(
      SODComponents.POLARIZATION_HOUSE
    );
    const senateAverage = this.populateAverageData(
      SODComponents.POLARIZATION_SENATE
    );

    const polarizationOverall = this.populateOverallData(SODComponents.TOTAL);
    const houseOverall = this.populateOverallData(
      SODComponents.POLARIZATION_HOUSE
    );
    const senateOverall = this.populateOverallData(
      SODComponents.POLARIZATION_SENATE
    );

    const totalCycleScores: ICycleScoreChartPoint[] =
      polarizationTotal.nodes.map((node) => {
        return {
          cycle: node.cycle,
          score: Math.round(node.score * 100),
          grade: node.grade,
          weight: node.weight,
        };
      });
    const houseCyclePercents: ICyclePercentChartPoint[] =
      polarizationHouse.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.score * 100),
        };
      });
    const senateCyclePercents: ICyclePercentChartPoint[] =
      polarizationSenate.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.score * 100),
        };
      });

    const lesserScoreSimilarStatesItems = lesserScoreSimilarStates.nodes.map(
      (node): ISimilarStatesItem => {
        return {
          abbreviation: node.code,
          score: Math.round(node.score * 100),
          title: node.state,
          grade: node.grade,
          itemLink: `/state/${generateUrlForStates(node.state)}/polarization`,
        };
      }
    );

    const greaterScoreSimilarStatesItems = greaterScoreSimilarStates.nodes.map(
      (node): ISimilarStatesItem => {
        return {
          abbreviation: node.code,
          score: Math.round(node.score * 100),
          title: node.state,
          grade: node.grade,
          itemLink: `/state/${generateUrlForStates(node.state)}/polarization`,
        };
      }
    );

    const stateUrl: string = generateUrlForStates(state);
    const { publicURL: iconUrl = '' } = icon.nodes[0];

    return (
      <StateLayout
        state={state}
        image={iconUrl}
        breadCrumbItems={breadCrumbs}
        title={`${this.translate('title')}(${polarizationOverall.state})`}
        description={this.translate('firstScoreDescription')}
      >
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={`${polarizationOverall.state} ${this.translate('title')}`}
          description={this.translate('firstScoreDescription')}
          imageResourceFileName={`embedded-polarization-score-${generateUrlForStates(
            polarizationOverall.state
          )}.png`}
        />
        <ContainerBox
          className="c-section--score is-criteria-grade"
          id="polarization"
        >
          <div className="c-score">
            <CriteriaGrade
              title={this.translate('scoreTitle')}
              averageScore={Math.round(polarizationAverage.score * 100)}
              averagePercent={Number(
                Number(polarizationAverage.value).toFixed(2)
              )}
              overallPercentage={Number(
                Number(polarizationOverall.value).toFixed(2)
              )}
              cycleScores={totalCycleScores}
              overallGrade={polarizationOverall.grade}
              overallScore={Math.round(polarizationOverall.score * 100)}
              descriptions={[polarizationScoreDesc]}
              isDecimal={true}
            />
            <Share
              absoluteLink={`/state/${stateUrl}/polarization`}
              embeddedLink={`/embedded/polarization/${stateUrl}`}
              imageResourceFileName={`embedded-polarization-score-${generateUrlForStates(
                polarizationOverall.state
              )}.png`}
            />
          </div>
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--similar">
          <SimilarStates
            lesserScoreStates={lesserScoreSimilarStatesItems}
            greaterScoreStates={greaterScoreSimilarStatesItems}
            activeState={{
              abbreviation: polarizationOverall.code,
              grade: polarizationOverall.grade,
              score: Math.round(polarizationOverall.score * 100),
              title: polarizationOverall.state,
              itemLink: `/state/${generateUrlForStates(
                polarizationOverall.state
              )}/polarization`,
            }}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--improve">
          <ImproveBox
            improveBoxItems={{
              title: this.translate('improvePolarization'),
              description: improvePolarizationDesc,
              items: [
                improvePolarizationSolution1,
                improvePolarizationSolution2,
                improvePolarizationSolution3,
              ],
            }}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--details">
          <PolarizationDetails
            items={[
              {
                title: this.translate('house'),
                description: houseDesc,
                value: Number(Number(houseOverall.value).toFixed(2)),
                averageValue: Number(Number(houseAverage.value).toFixed(2)),
                isPercent: false,
                chartData: houseCyclePercents,
              },
              {
                title: this.translate('senate'),
                description: senateDesc,
                value: Number(Number(senateOverall.value).toFixed(2)),
                averageValue: Number(Number(senateAverage.value).toFixed(2)),
                isPercent: false,
                chartData: senateCyclePercents,
              },
            ]}
          />
        </ContainerBox>
      </StateLayout>
    );
  }

  private populateOverallData(component: SODComponents): ISODNode {
    const { overall } = this.props.data;
    const componentOverall: ISODNode = overall.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.PARTICIPATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };

    return componentOverall;
  }

  private populateAverageData(component: SODComponents): ISODNode {
    const { average } = this.props.data;
    const componentAverage: ISODNode = average.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.PARTICIPATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };
    return componentAverage;
  }
}

export const query = graphql`
  query StatePolarization($state: String, $score: Float) {
    polarizationTotal: allSheetDataPolarization(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }
    polarizationHouse: allSheetDataPolarization(
      filter: {
        state: { eq: $state }
        component: { eq: "House" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
      }
    }
    polarizationSenate: allSheetDataPolarization(
      filter: {
        state: { eq: $state }
        component: { eq: "Senate" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
      }
    }
    lesserScoreSimilarStates: allSheetDataPolarization(
      sort: { fields: score, order: DESC }
      filter: {
        state: { ne: $state }
        component: { eq: "Total" }
        cycle: { eq: "Overall" }
        score: { lte: $score }
        abbrev: { ne: "US Avg" }
      }
      limit: 3
    ) {
      nodes {
        code
        score
        state
        grade
      }
    }
    greaterScoreSimilarStates: allSheetDataPolarization(
      sort: { fields: score, order: ASC }
      filter: {
        state: { ne: $state }
        component: { eq: "Total" }
        cycle: { eq: "Overall" }
        score: { gte: $score }
        abbrev: { ne: "US Avg" }
      }
      limit: 3
    ) {
      nodes {
        code
        score
        state
        grade
      }
    }
    average: allSheetDataPolarization(
      filter: { state: { eq: "US Average" }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }

    overall: allSheetDataPolarization(
      filter: { state: { eq: $state }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }

    icon: allFile(
      filter: {
        name: { eq: $state }
        ext: { eq: ".svg" }
        relativeDirectory: { eq: "images/states" }
      }
    ) {
      nodes {
        name
        publicURL
        relativeDirectory
      }
    }
  }
`;
